<script lang="ts" setup>
import { useCookie } from '#imports'
import { computed, onBeforeMount } from 'vue'
import {
  HaIcon,
  HaLoader,
  useNotifications,
  HaToaster,
  useI18n,
  HaButton
} from '@ha/components-v3'
import { functions } from '@ha/helpers'
import useStoreUser from '@/stores/useStoreUser'
import { faArrowRight, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { useRoute } from 'vue-router'
import useStoreReferrer from '@/stores/useStoreReferrer'

const route = useRoute()
const i18n = useI18n()
const accessTokenName = 'tm5-HelloAsso'
const accessToken = useCookie(accessTokenName)
const storeUser = useStoreUser()
const { haToaster } = useNotifications()
const storeReferrer = useStoreReferrer()

const connectionRedirection = computed(() => {
  const queries = Object.entries(route.query).reduce(
    (acc, [key, val]) => `${acc}&${key}=${encodeURIComponent(String(val))}`,
    `from=${String(route.name)}`
  )

  return `/connexion?${queries}`
})

const referrerLogo = computed<string | undefined>(() => {
  if (!storeReferrer.referrer) return

  const { customLogo, partnerLogo, partnerRectangleLogo } =
    storeReferrer.referrer

  // Order matters
  return customLogo || partnerRectangleLogo || partnerLogo
})

const displayHaLogo = computed(() => {
  if (!storeReferrer.referrer) return true
  return !!referrerLogo.value && storeReferrer.referrer.displayHelloassoLogo
})

onBeforeMount(() => {
  if (!accessToken.value) return
  const { userId } = functions.getAccessTokenInformations(accessToken.value)
  if (userId) storeUser.fetchUser()
})
</script>

<template>
  <div
    hads
    class="registration-wrapper"
  >
    <HaToaster ref="haToaster" />
    <div class="registration-wrapper__header">
      <div class="registration-wrapper__header__logos">
        <img
          v-if="displayHaLogo"
          class="registration-wrapper__header__logos__ha-logo"
          src="/img/registration/logo.svg"
          alt="Logo HelloAsso"
        />

        <HaLoader v-if="storeReferrer.isLoading" />

        <template v-else-if="storeReferrer.referrer && referrerLogo">
          <HaIcon
            v-if="displayHaLogo"
            :icon="faPlus"
          />

          <img
            class="registration-wrapper__header__logos__referrer"
            :id="storeReferrer.referrer.id.toString()"
            :src="referrerLogo"
            :alt="`${$t('components.logo.alt')} ${storeReferrer.referrer.referrer}`"
          />
        </template>
      </div>

      <div
        v-if="!storeUser.isUserConnected"
        class="registration-wrapper__header__connection-link"
      >
        <p>{{ i18n.t('layout.alreadyHaveAnAccount') }}</p>

        <HaButton
          :to="connectionRedirection"
          variant="link"
          icon-side="right"
          color="base"
        >
          <template #icon>
            <HaLoader v-if="storeUser.isLoading" />
            <HaIcon
              v-else
              :icon="faArrowRight"
            />
          </template>

          {{ i18n.t('layout.connection') }}
        </HaButton>
      </div>
    </div>
    <div class="registration-wrapper__body">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
:root {
  /* Mint to Cobalt */
  --ha-color-primary: hsl(245, 60%, 53%);
  --ha-color-primary-h: 245;
  --ha-color-primary-s: 60%;
  --ha-color-primary-l: 53%;

  /* Dream to Mint */
  --ha-color-secondary: hsl(148, 61%, 56%);
  --ha-color-secondary-h: 148;
  --ha-color-secondary-s: 61%;
  --ha-color-secondary-l: 56%;

  /* Dream */
  --ha-color-tertiary: hsl(220, 88%, 80%);
  --ha-color-tertiary-h: 220;
  --ha-color-tertiary-s: 88%;
  --ha-color-tertiary-l: 80%;
}
</style>
<style lang="scss" scoped>
.registration-wrapper {
  min-height: 100vh;
  background-image: url('/img/registration/background-pattern.svg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: 160%;
  padding: var(--hads-spacing-400) var(--hads-spacing-600);

  @include mediaQuery(900, 'max') {
    padding: var(--hads-spacing-300);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: var(--hads-spacing-500);

    &__logos {
      display: flex;
      align-items: center;
      gap: var(--hads-spacing-200);

      &__ha-logo {
        width: 100px;
      }

      &__referrer {
        max-width: 150px;
        max-height: 80px;
      }
    }

    @include mediaQuery(900, 'max') {
      flex-direction: column-reverse;

      &__logos {
        margin-top: var(--hads-spacing-400);
      }
    }
    &__connection-link {
      display: flex;
      align-items: center;
      color: var(--hads-color-iris);

      :deep(a) {
        margin-left: var(--hads-spacing-300);
        padding: 0;
      }

      p {
        margin: 0;
      }
    }
  }
  &__body {
    max-width: 950px;
    margin: auto;
  }
}
</style>
